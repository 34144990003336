import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import NavLinks from "./header/nav-links";
import logoLight from "../../static/assets/images/white-logo.png";
import i18n from "i18n-js";
import en from "../locales/translations/en.json";
import kn from "../locales/translations/kn.json";
import tl from "../locales/translations/tl.json";
import hn from "../locales/translations/hn.json";
i18n.translations = {
  en: en,
  kn: kn,
  tl: tl,
  hn: hn,
};

i18n.fallbacks = false;
i18n.locale = "en";
const MobileNav = () => {
  const setLanguage = (lang) => {
    console.log("lang", lang);
    i18n.locale = lang;
  };
  const [hasMounted, setHasMounted] = useState(false);
  const handleMobileCloseMenu = (e,lang) => {
    console.log("close menu")
    document.querySelector(".mobile-nav__wrapper").classList.toggle("expanded");
    setLanguage(lang);
  };
  const mobileMenu = () => {
    console.log("hello world");
    let mobileNavToggler = document.querySelectorAll(".mobile-nav__toggler");
    console.log("mobile navigation", mobileNavToggler);
    if (mobileNavToggler) {
      mobileNavToggler.forEach((mobileNavTogglerBtn) => {
        mobileNavTogglerBtn.addEventListener("click", function (e) {
          console.log("clicked");
          document
            .querySelector(".mobile-nav__wrapper")
            .classList.toggle("expanded");
          e.preventDefault();
        });
      });
    } else {
      console.log("else block");
    }
    //Close Mobile Menu
    let sideMenuCloser = document.querySelectorAll(".side-menu__toggler");
    console.log(sideMenuCloser, "hiii");
    if (sideMenuCloser) {
      sideMenuCloser.forEach((sideMenuCloserBtn) => {
        sideMenuCloserBtn.addEventListener("click", function (e) {
          document
            .querySelector(".mobile-nav__wrapper")
            .classList.remove("expanded");
          e.preventDefault();
        });
      });
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      mobileMenu();
      setHasMounted(true);
      return () => {
        mobileMenu();
      };
    }
  }, [hasMounted]);

  if (!hasMounted) {
    return null;
  }
  return (
    <div className="mobile-nav__wrapper">
      <div className="mobile-nav__overlay side-menu__toggler side-menu__block-overlay"></div>
      <div className="mobile-nav__content">
        <span className="mobile-nav__close side-menu__toggler  side-menu__close-btn">
          <i className="far fa-times"></i>
        </span>
        <div className="logo-box">
          <Link to="/">
            <img src={logoLight} alt="" />
          </Link>
        </div>
        <div className="mobile-nav__container">
          <NavLinks setLanguage={setLanguage} onSelect={handleMobileCloseMenu} />
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
