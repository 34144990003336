import React, { useState } from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
import { Container, Row, Col } from "react-bootstrap";
import logoLight from "../../static/assets/images/logo3.png";
import i18n from "i18n-js";
const Footer = () => {
  const [lang, setLang] = useState("en");
  return (
    <section className="site-footer">
      <div className="main-footer ">
        <Row>
          <Col lg={4} md={4} sm={12} className="pt-30 pb-30 footer-block1" >
            <div className="footer-widget mb-40 footer-widget__about" >
              <div className="text-center">
                <span className="align-bottom">
                  <Link to="/">
                    <img
                      src={logoLight}
                      className="footer-widget__logo mobile-logo-align"
                      alt=""
                    />
                  </Link>
                </span>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} className="pt-30 pb-30">
            <div className="footer-widget mb-40 footer-widget__about footer-col-margin">
              <h3 className="footer-widget__title">{i18n.t('add')}</h3>
              <ul className="list-unstyled footer-widget__contact">
                <li>
                  <i className="azino-icon-telephone"></i>
                  <a href="#none">
                    {i18n.t('num')}
                  </a>
                </li>
                <li>
                  <i className="azino-icon-email"></i>
                  <a href="#none">
                    {i18n.t('email')}
                  </a>
                </li>
                <li>
                  <i className="azino-icon-pin"></i>
                  <a href="#none">
                    {i18n.t('address')}
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} className="pt-30 pb-30">
            <div className="footer-widget footer-widget__link mb-40 footer-col-margin">
              <h3 className="footer-widget__title">{i18n.t('exp')}</h3>
              <ul className="list-unstyled footer-widget__link-list">
                <li>
                  <Link to="/Work/Products">{i18n.t('smallProducts')}</Link>
                </li>
                <li>
                  <Link to="/Approach/AshaWorkers">{i18n.t('foot-app')}</Link>
                </li>
                <li>
                  <Link to="/About/OurStory">{i18n.t('foot-about')}</Link>
                </li>
                <li>
                  <Link to="/Resources/News">{i18n.t('foot-news')}</Link>
                </li>
                <li>
                  <Link to="/Blog">{i18n.t('foot-blog')}</Link>
                </li>
                <li>
                  <a to="/Donate" activeClassName="active-navlink">
                    {/* <p className="thm-btn-involved">{i18n.t("involved")}</p> */}
                    <button type="text" className="btn btn-secondary footer-donate">{i18n.t("involved")}</button>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
      <div className="footer-bottom">
        <div className="container">
          <ScrollLink
            to="wrapper"
            smooth={true}
            duration={500}
            className="scroll-to-top"
          >
            <i className="far fa-angle-up"></i>
          </ScrollLink>
          <p>© thereddot.in</p>
          <div className="footer-social">
            <a href="#none" aria-label="twitter">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#none" aria-label="facebook">
              <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none" aria-label="instagram">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
