import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import NavLinks from "./nav-links";
import logoLight from "../../../static/assets/images/logo2.png";
import { Container, Row, Col } from "react-bootstrap";
import i18n from "i18n-js";
import en from "../../locales/translations/en.json";
import kn from "../../locales/translations/kn.json";
import tl from "../../locales/translations/tl.json";
import hn from "../../locales/translations/hn.json";

i18n.translations = {
  en: en,
  kn: kn,
  tl: tl,
  hn: hn,
};
i18n.fallbacks = false;
i18n.locale = "en";
const StickyHeader = ({ extraClassName, props }) => {
  const [sticky, setSticky] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else if (window.scrollY < 0) {
      setSticky(false);
    }
  };
  const setLanguage = (e,lang) => {
    i18n.locale = lang
  }
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);
  return (
    <div className={`stricky-header stricked-menu stricky-fixed`}>
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={1} xl={2}>
            <div className="logo-align text-center logo-padding-top">
              <Link to="/">
                <img src={logoLight}  alt="thereddot logo" />
              </Link>
            </div>
          </Col>
          <Col sm={12} md={12} lg={11} xl={10}>
            <div className="sticky-header__content" >
              <NavLinks onSelect={setLanguage}/>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StickyHeader;
