import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import NavLinks from "./nav-links";
import logo from "../../../static/assets/images/logo4.png";
const HeaderOne = () => {
  return (
    <header className="main-header">
      <Container fluid>
        <div className="inner-container">
          <Row>
            <Col sm={12} md={12} lg={12} xl={2}>
              <div className="logo-box logo-align">
                <Link to="/">
                  <img src={logo}  alt="thereddot.in logo" />
                </Link>
                <span className="fa fa-bars mobile-nav__toggler"></span>
              </div>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={10}
              className="d-none d-md-none d-lg-none d-xl-block"
            >
              <nav className="main-menu dynamic-radius">
                <NavLinks />
              </nav>
            </Col>
          </Row>
        </div>
      </Container>
    </header>
  );
};

export default HeaderOne;
