import React, { useState,useEffect } from "react";
import { Link } from "gatsby";
import i18n from "i18n-js";

const NavLinks = ({ extraClassName, ...props }) => {
  let locationVariable = window.location.href.split("?")
  const [lang, setLang] = useState("en");
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
    document
      .querySelector(".mobile-nav__wrapper")
      .classList.hide("expanded");
  };
  function English() {
    return (
      <Link
        to={locationVariable[1] ? window.location.pathname + '?' + locationVariable[1] : window.location.pathname}
        onClick={(e) => {
          props.onSelect(e, "en");
        }}
      >
        English
      </Link>
    );
  }
  function Hindi() {
    return (

      <Link
        to={locationVariable[1] ? window.location.pathname + '?' + locationVariable[1] : window.location.pathname}
        onClick={(e) => {
          props.onSelect(e, "hn");

        }}
      >
        हिन्दी
      </Link>
    );

  };
  function Telugu() {
    return (
      <Link
        to={locationVariable[1] ? window.location.pathname + '?' + locationVariable[1] : window.location.pathname}
        onClick={(e) => {
          props.onSelect(e, "tl");
        }}
      >
        తెలుగు
      </Link>
    );
  }
  function Kannada() {
    return (
      <Link
        to={locationVariable[1] ? window.location.pathname + '?' + locationVariable[1] : window.location.pathname}
        onClick={(e) => {
          props.onSelect(e, "kn");
        }}
      >
        ಕನ್ನಡ
      </Link>
    );

  }
  const checkActive = (match, location) => {
    //some additional logic to verify you are in the home URI
    console.log("location hdsudud")
    if (!location) return false;
    const { pathname } = location;
    console.log(pathname);
    return pathname === "/";
  }
 
  return (
    <ul className={`main-menu__list ${extraClassName}`} >
      <li className="dropdown" data-aos='fade-in'>
        <Link to="/" activeClassName="active-navlink">{i18n.t("what")}</Link>
      </li>
      <li className="dropdown">
        <Link to="/About/OurStory" activeClassName="active-navlink">{i18n.t("ab")}</Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/About/OurStory">{i18n.t("story")}</Link>
          </li>
          <li>
            <Link to="/About/VisionMission">{i18n.t("vis")}</Link>
          </li>
        </ul>
      </li>
      <li className="dropdown">
        <Link to="/Work/Products" activeClassName="active-navlink">{i18n.t("products")}</Link>
        {/* <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button> */}
        {/* <ul className="dropdown-list">
          <li>
            <Link to="/Work/MHH" >{i18n.t("mhh")}</Link>
          </li>
          <li>
            <Link to="/Work/Mental-health">{i18n.t("mh")}</Link>
          </li>
          <li>
            <Link to="/Work/HomeRemedies">{i18n.t("nutri")}</Link>
          </li>
          <li>
            <Link to="/Work/Products">{i18n.t("product")}</Link>
          </li>
         
        </ul> */}
      </li>
      <li className="dropdown">
        <Link to="/Approach/AshaWorkers" activeClassName="active-navlink">{i18n.t("app")}</Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/Approach/AshaWorkers">{i18n.t("asha")}</Link>
          </li>
          <li>
            <Link to="/Approach/RotaryClubs">{i18n.t("rotary-clubs")}</Link>
          </li>
          <li>
            <Link to="/Approach/GovernmentSchools">{i18n.t("govn-schools")}</Link>
          </li>
          <li>
            <Link to="/Approach/Individuals">{i18n.t("indiv")}</Link>
          </li>
          <li>
            <Link to="/Approach/Organisation">{i18n.t("org")}</Link>
          </li>
        </ul>
      </li>

      <li className="dropdown">
        <Link to="/Resources/News" activeClassName="active-navlink">{i18n.t("resource")}</Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/Resources/News">{i18n.t("news")}</Link>
          </li>
          <li>
            <Link to="/Resources/Papers">{i18n.t("research")}</Link>
          </li>
          <li>
            <Link to="/Resources/Video">{i18n.t("video")}</Link>
          </li>
        </ul>
      </li>
      <li className="dropdown">
        <Link to="/Blog" activeClassName="active-navlink">{i18n.t("blog")}</Link>
      </li>
      <li>
        <Link to="/Donate" activeClassName="active-navlink">
          <p className="thm-btn-involved">{i18n.t("involved")}</p>
        </Link>
      </li>
      <li className="dropdown lang-btn-desk">
        <Link className="blink_me thm-btn-lang lang-btn-mobile">
          <span>{i18n.t("lang")}</span>
        </Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            {English()}
          </li>
          <li>
            {Hindi()}
          </li>
          <li>{Kannada()}</li>
          <li>
            {Telugu()}
            {/* </Link> */}
          </li>
        </ul>
      </li>
      {/* <li>
        <Button>GIVE</Button>
      </li> */}
    </ul>
  );
};

export default NavLinks;
