import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import MobileNav from "./mobile-nav";
import AppleTouch from "../../static/assets/images/logo1.png";
import Fevicon32 from "../../static/assets/images/logo1.png";
import Fevicon16 from "../../static/assets/images/logo1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/swiper-bundle.min.css";
import "react-modal-video/css/modal-video.min.css";
import "../../static/assets/css/azino-icons.css";
import "../../static/assets/css/fontawesome-all.min.css";
import "../../static/assets/css/main.css";
import "swiper/swiper-bundle.min.css";

const Layout = ({ pageTitle, children }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const handleRadius = () => {
    const dynamicRadius = document.querySelectorAll(".dynamic-radius");
    dynamicRadius.forEach(function (btn) {
      let btnHeight = btn.offsetHeight;
      btn.style.borderBottomLeftRadius = btnHeight / 2 + "px";
      btn.style.borderTopLeftRadius = btnHeight / 2 + "px";
    });
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      handleRadius();
      setHasMounted(true);
      return () => {
        handleRadius();
      };
    }
  }, [hasMounted]);

  if (!hasMounted) {
    return null;
  }
  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{pageTitle}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouch} />
        <link rel="icon" type="image/png" sizes="32x32" href={Fevicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Fevicon16} />
      </Helmet>
      <div className="page-wrapper" id="wrapper">
        {children}
      </div>
      <MobileNav />
    </div>
  );
};

export default Layout;
